body {
  padding: 0 1em;
}

button {
  margin-right: 10px;
}

button:last-child {
  color: red;
}

.editor-wrapper {
  position: relative;
  padding: 1em;
  margin-top: 1em;
  background-color: rgba(0, 0, 0, 0.2);
  width: fit-content;
}

/* ----editor header----- */
.editor-header {
  position: absolute;
  top: 1.4em;
  width: 100%;
}

.editor-window--actions {
  position: absolute;
  top: 0;
  left: 1em;
  width: 80px;
}


.editor-window--action {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 6px;
}

.editor-window--action.exit {
  background-color: #ff5f56;
  -webkit-box-shadow: 0 0 1px #e0443e;
  box-shadow: 0 0 1px #e0443e;
}

.editor-window--action.minimize {
  background-color: #ffbd2e;
  -webkit-box-shadow: 0 0 1px #dea123;
  box-shadow: 0 0 1px #dea123;
}

.editor-window--action.maximize {
  background-color: #27c93f;
  -webkit-box-shadow: 0 0 1px #1aab29;
  box-shadow: 0 0 1px #1aab29;
}

.watermark,
.name {
  background: transparent;
  border: 0 none;
  color: #fff;
  width: 100%;
  text-align: center;
}

.watermark {
  text-align: right;
  color: cornsilk;
}

/* ----- editor content ----- */

#editor-content {
  min-width: 20em;
  min-height: 10em;
  outline: 1px dashed gray;
}

#editor-content>div {
  padding: 1em;
  padding-top: 3em;
  color: #fff;
  background-color: transparent;
  border-radius: 1em;
}

.x2,
.x2>div {
  font-size: 1.2rem !important;
  line-height: 2rem !important;
}

.x3,
.x3>div {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.x4,
.x4>div {
  font-size: 2rem !important;
  line-height: 2.4rem !important;
}

/* --- editor content --- */

.editor-footer {
  position: absolute;
  bottom: 1.2em;
  right: 1.4em;
  width: 100%;
}